import React from "react";
import backgroundImage from "images/banners/bg-about-mission.jpg";
import {
  AboutUsNav,
  BannerTitle,
  Layout,
  PageContainer,
  SEO,
  TeamMembers,
} from "components";

const TeamPage = ({ location }) => {
  const title = "About Us";
  const description =
    "Meet the world class team building the future of lab science";

  return (
    <Layout clearNav location={location}>
      <SEO
        image={backgroundImage}
        title={`${title} | Team`}
        description={description}
        uri={location.pathname}
      />
      <BannerTitle image={backgroundImage}>{title}</BannerTitle>
      <PageContainer>
        <AboutUsNav section={location && location.pathname} />
        <TeamMembers />
      </PageContainer>
    </Layout>
  );
};

export default TeamPage;
